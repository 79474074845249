<div id="content-report-export" class="data-section">
    <ion-row class="section-header">
        <ion-col class="ion-no-padding">
            Liaison
        </ion-col>
    </ion-row>

    <ion-row class="liaison-data">
        <ion-col class="ion-no-padding">
            <ion-row>
                <ion-col class="ion-no-padding">
                    {{report?.liaison?.first_name ?? 'No Name'}} {{report?.liaison?.last_name ?? ''}}
                </ion-col>
            </ion-row>
            <ion-row *ngIf="report?.liaison?.email">
                <ion-col class="ion-no-padding">
                    <a [href]="'mailto: ' + report.liaison.email">{{report.liaison.email ?? 'No Email'}}</a>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="report?.liaison?.phone_number">
                <ion-col class="ion-no-padding">
                    <a [href]="'tel: ' + report.liaison.phone_number">{{report.liaison.country_code ? ('+' + report.liaison.country_code + '') : ''}}{{report.liaison.phone_number ?? 'No Phone'}}</a>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="reportData?.allIssues.length > 0">
        <ion-col class="ion-no-padding">
            Issues
        </ion-col>
    </ion-row>

    <ion-row class="issue-row" *ngFor="let issue of reportData?.allIssues">
        <ion-col class="ion-no-padding">
            <ion-row class="issue-top-section">
                <ion-col class="ion-no-padding issue-title">
                    <span [ngClass]="{ 'concern-text': issue.concern }">{{issue.description}} {{issue.concern ? (' - ' + issue.concern_written) : ''}}</span>
                </ion-col>
                <ion-col *ngIf="issue.concern" size="auto" class="ion-no-padding issue-badge-col">
                    <div class="concern-badge badge">
                        <ion-icon name="warning-outline"></ion-icon>
                        <span>Concern Written</span>
                    </div>
                </ion-col>
                <ion-col size="auto" class="ion-no-padding issue-badge-col status-badge-col">
                    <div [ngStyle]="{'background-color': statusColorMap.get(issue.status)}" 
                    class="status-badge badge">{{statusMap.get(issue.status) ?? 'No Status'}}</div>
                </ion-col>
            </ion-row>
            <ion-row>

            </ion-row>
            <ion-row class="part-info-text">
                <ion-col *ngIf="issue.parts_found" size="auto" class="ion-no-padding">
                    <span>{{issue.parts_found}} units found</span>
                </ion-col>
                <ion-col *ngIf="issue.impacted_parts" size="auto" class="ion-no-padding">
                    <span>{{issue.impacted_parts}} impacted</span> 
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.actionUpdate && issue.status === 'new'" class="action-taken-section">
                <ion-col class="ion-no-padding">
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <span>Action Taken: {{issue.actionUpdate.content}}</span>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <span>Area in Plant Where Found: {{issue.actionUpdate.area_found}}</span>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_updates.length > 0 && issue.grouped_updates.length > 0">
                <ion-col class="ion-no-padding">
                    Updates:
                </ion-col>
            </ion-row>
            <ion-row [ngClass]="{'closing-update': update.is_closing_update}" class="issue-update-row"  *ngFor="let update of issue.issue_updates">
                <ion-col class="ion-no-padding">
                    <ion-row *ngIf="update.time_created">
                        <ion-col class="ion-no-padding">
                            {{update.time_created}}
                        </ion-col>
                        <ion-col *ngIf="update.area_found" size="auto" class="ion-no-padding area-found-update-col">
                            <ion-icon name="location-outline"></ion-icon>
                            <span>{{update.area_found}}</span>
                        </ion-col>
                        <ion-col *ngIf="update.is_closing_update" size="auto" class="ion-no-padding">
                            <span class="update-is-closed">Closed</span>
                        </ion-col>
                    </ion-row>
                    <ion-row class="part-info-text">
                        <ion-col *ngIf="update.parts_found" size="auto" class="ion-no-padding">
                            <span>{{update.parts_found}} parts affected</span>
                        </ion-col>
                        <ion-col *ngIf="update.clean_point_required" size="auto" class="ion-no-padding">
                            <span>Clean point: {{update.clean_point_date}}</span> 
                        </ion-col>
                        <ion-col *ngIf="update.issue_serial_numbers.length > 0" size="auto" class="ion-no-padding">
                            <span>Traceability Barcodes: {{update.issue_serial_numbers}}</span> 
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="update.content">
                        <span>{{update.content}}</span>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="sub-header" *ngIf="issue.issue_images.length > 0">
                <ion-col class="ion-no-padding">
                    Attachments:
                </ion-col>
            </ion-row>
            <ion-row *ngIf="issue.issue_images.length > 0">
                <app-attachment-upload
                    #attachmentUpload
                    [condensed]="true"
                    [id]="issue.id"
                    [isDisabled]="true"
                    [context]="'issue'"
                    [startHidden]="true"
                    [showButtons]="false"
                    [showAttachmentCount]="true"
                    [paddingAmount]="'0px'"
                    [imageUIOnly]="true"
                    [showDelete]="false"
                ></app-attachment-upload>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="section-header" *ngIf="reportData?.report.sorts.length > 0">
        <ion-col class="ion-no-padding">
            Sorts
        </ion-col>
    </ion-row>

    <ng-container *ngIf="reportData?.report.sorts && (reportData?.report.sorts.length > 0)">
        <ion-row class="sub-row" *ngFor="let sort of reportData?.report?.sorts">
            <ion-col class="ion-no-padding">
                <ion-row class="sort-header">
                    <ion-col class="ion-no-padding">
                        Part #
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        Qty
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        Rejected
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        Reworked
                    </ion-col>
                </ion-row>
                <ion-row class="table-data">
                    <ion-col class="ion-no-padding">
                        {{sort.part_numbers?.part_number ? sort.part_numbers?.part_number : sort.part_numbers?.barcode}}
                    </ion-col>
                    <ion-col class="ion-no-padding">
                        {{sort.total}}
                    </ion-col>
                    <ion-col class="ion-no-padding reject-text">
                        {{sort.rejectsQuantity}}
                    </ion-col>
                    <ion-col class="ion-no-padding rework-text">
                        {{sort.reworksQuantity}}
                    </ion-col>
                </ion-row>

                <div class="defect-container">
                    <ion-row class="sort-header" *ngIf="sort.defects.length > 0">
                        <ion-col class="ion-no-padding">
                            Defect
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            Rejected
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            Reworked
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            Pass Rate
                        </ion-col>
                        <ion-col class="image-icon">
                            
                        </ion-col>
                    </ion-row>

                    <ion-row class="table-data" *ngFor="let defect of sort.defects">
                        <ion-col class="ion-no-padding" (click)="openDefectReport(defect, sort)">
                            {{defect.name}}
                        </ion-col>
                        <ion-col class="ion-no-padding reject-text" (click)="openDefectReport(defect, sort)">
                            {{defect.rejects}}
                        </ion-col>
                        <ion-col class="ion-no-padding rework-text" (click)="openDefectReport(defect, sort)">
                            {{defect.reworks}}
                        </ion-col>
                        <ion-col class="ion-no-padding" (click)="openDefectReport(defect, sort)">
                            {{defect.rate | percent}}
                        </ion-col>
                        <ion-col class="image-icon ion-no-padding" (click)="openDefectImages(defect)">
                            <ion-icon *ngIf="defect.images.length > 0" name="images-outline"></ion-icon>
                        </ion-col>
                    </ion-row>
                </div>
            </ion-col>
        </ion-row>
    </ng-container>

    <ion-row class="section-header" *ngIf="report?.dailyActivity.length > 0">
        <ion-col class="ion-no-padding">
            Daily Activity Summary
        </ion-col>
    </ion-row>

    <ng-container>
        <ng-container *ngFor="let activity of reportData?.report.dailyActivity">
            <ion-row class="activity-data" *ngIf="activity?.activity?.length > 0">
                <ion-col class="ion-no-padding">
                    <ng-container *ngIf="report.id.length > 1">
                        <span>{{activity.activity}}</span>
                    </ng-container>
                    
                    <ng-container *ngIf="report.id.length === 1">
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="activity-text">Daily Activity:</span> <br><span>{{activity.activity}}</span>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="activity-text">Production Count:</span> {{activity.productionCount}}
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="activity-text">Scrap Count:</span> {{activity.scrapCount}}
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ng-container>
    </ng-container>

</div>