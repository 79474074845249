import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppHeaderComponent } from "./header/app-header.component";
import { BackButtonComponent } from "./buttons/back-button/back-button.component";
import { AccordionComponent } from "./components/accordion/accordion.components";
import { AccountOverviewFooterComponent } from "./account-overview-footer/account-overview-footer.component";
import { ReportSortFooterComponent } from "./report-sort-footer/report-sort-footer.component";
import { AttachmentUploadComponent } from "./attachment-upload/attachment-upload.component";
import { PreviewAttachmentComponent } from "./preview-attachment/preview-attachment.component";
import { IssueCardComponent } from "./issue-card/issue-card.component";
import { RouterModule } from "@angular/router";
import { ReportSortCardComponent } from "./report-sort-card/report-sort-card/report-sort-card.component";
import { AdminNavComponent } from "./admin-nav/admin-nav.component";
import { AdminCardComponent } from "./admin-card/admin-card.component";
import { CreateEditIssueComponent } from "./create-edit-issue/create-issue.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ProfilePopupComponent } from "./header/components/profile-popup/profile-popup.component";
import { AccountInfoComponent } from "./account-info/account-info.component";
import { CalendarInputComponent } from "./calendar-input/calendar-input.component";
import { CalendarPopoverComponent } from "./calendar-popover/calendar-popover.component";
import { ScrollbarComponent } from "./scrollbar/scrollbar.component";
import { AccountInfoHeaderComponent } from "./account-info-header/account-info-header.component";
import { BarcodeScannerOverlayComponent } from "./barcode-scanner-overlay/barcode-scanner-overlay.component";
import { DefectAttachmentUploadComponent } from "./defect-attachment-upload/defect-attachment-upload.component";
import { CloseOpenIssueComponent } from "./close-open-issue-popup/close-open-issue-popup.component";
import { CreateDefectComponent } from "./create-defect/create-defect.component";
import { RequestApprovalComponent } from "../../features/liaison/pages/liaison-time/components/request-approval/request-approval.component";
import { MobileNavComponent } from "./admin-nav/components/mobile-nav/mobile-nav.component";
import { ContactUsComponent } from "./header/components/contact-us-popup/contact-us-popup.component";
import { SendToCustomerComponent } from "./send-to-customer-popup/send-to-customer-popup.component";
import { ColumnSortHeaderComponent } from "./column-sort-header/column-sort-header.component";
import { ColumnSortContainerComponent } from "./column-sort-container/column-sort-container.component";
import { CreateIssueUpdateComponent } from "./create-issue-update/create-issue-update.component";
import { LiaisonReportContentComponent } from "./liaison-report-content/liaison-report-content.component";
import { RejectImagePopupComponent } from "./reject-image-popup/reject-image-popup.component";
import { RejectDefectReportComponent } from "./reject-defect-report/reject-defect-report.component";

import { IsActivePipe } from "../pipes/isActive.pipe";
import { DateOrderPipe } from "../pipes/shifts-in-date-order";
import { OrderByNamePipe } from "../pipes/order-by-name.pipe";
import { FilterSearchPipe } from "../pipes/filter-search.pipe";
import { FilterLocationSearchPipe } from "../pipes/filter-search-location.pipe";
import { FilterUserSearchPipe } from "../pipes/filter-search-user.pipe";
import { FilterAccountSearchPipe } from "../pipes/filter-search-account.pipe";
import { OrderByNameUsersPipe } from "../pipes/order-by-name-user.pipe";
import { ReportIssueComponent } from "./header/components/report-issue/report-issue.component";
import { CurrencyInputComponent } from "./currency-input/currency-input.component";
import { RefreshPipe } from "../pipes/refresh.pipe";

import { MaskService, NgxMaskModule } from 'ngx-mask';

import { OrderByStartDatePipe } from "../pipes/sort-hours.pipe";

import { BarcodeScannerComponent } from "./barcode-scanner/barcode-scanner.component";



@NgModule({
  imports: [RouterModule, IonicModule, CommonModule, FormsModule, ReactiveFormsModule, NgxMaskModule],
  declarations: [
    AppHeaderComponent,
    BackButtonComponent,
    AccordionComponent,
    AccountOverviewFooterComponent,
    ReportSortFooterComponent,
    AttachmentUploadComponent,
    PreviewAttachmentComponent,
    IssueCardComponent,
    ReportSortCardComponent,
    AdminNavComponent,
    AdminCardComponent,
    CreateEditIssueComponent,
    ProfilePopupComponent,
    AccountInfoComponent,
    CalendarPopoverComponent,
    CalendarInputComponent,
    ScrollbarComponent,
    AccountInfoHeaderComponent,
    BarcodeScannerOverlayComponent,
    DefectAttachmentUploadComponent,
    CloseOpenIssueComponent,
    CreateDefectComponent,
    IsActivePipe,
    DateOrderPipe,
    OrderByNamePipe,
    FilterSearchPipe,
    FilterLocationSearchPipe,
    FilterUserSearchPipe,
    FilterAccountSearchPipe,
    OrderByNameUsersPipe,
    MobileNavComponent,
    CurrencyInputComponent,
    ContactUsComponent,
    SendToCustomerComponent,
    OrderByStartDatePipe,
    ReportIssueComponent,
    BarcodeScannerComponent,
    ColumnSortHeaderComponent,
    ColumnSortContainerComponent,
    CreateIssueUpdateComponent,
    RefreshPipe,
    LiaisonReportContentComponent,
    RejectImagePopupComponent,
    RejectDefectReportComponent
  ],
  exports: [
    AppHeaderComponent,
    BackButtonComponent,
    AccordionComponent,
    AccountOverviewFooterComponent,
    ReportSortFooterComponent,
    AttachmentUploadComponent,
    PreviewAttachmentComponent,
    IssueCardComponent,
    ReportSortFooterComponent,
    ReportSortCardComponent,
    AdminNavComponent,
    AdminCardComponent,
    CreateEditIssueComponent,
    ProfilePopupComponent,
    AccountInfoComponent,
    CalendarPopoverComponent,
    CalendarInputComponent,
    ScrollbarComponent,
    AccountInfoHeaderComponent,
    BarcodeScannerOverlayComponent,
    DefectAttachmentUploadComponent,
    CloseOpenIssueComponent,
    CreateDefectComponent,
    IsActivePipe,
    DateOrderPipe,
    OrderByNamePipe,
    FilterSearchPipe,
    FilterLocationSearchPipe,
    FilterUserSearchPipe,
    FilterAccountSearchPipe,
    OrderByNameUsersPipe,
    MobileNavComponent,
    CurrencyInputComponent,
    ContactUsComponent,
    SendToCustomerComponent,
    OrderByStartDatePipe,
    ReportIssueComponent,
    BarcodeScannerComponent,
    ColumnSortHeaderComponent,
    ColumnSortContainerComponent,
    CreateIssueUpdateComponent,
    RefreshPipe,
    LiaisonReportContentComponent,
    RejectImagePopupComponent,
    RejectDefectReportComponent
  ],
  providers: [ MaskService ]
})
export class AppUIModule {}
