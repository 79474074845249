import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { SplashScreen } from "@capacitor/splash-screen";
import { Preferences } from "@capacitor/preferences";
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';

import { Platform } from "@ionic/angular";

import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Capacitor } from "@capacitor/core";
import { FcmService } from "./shared/services/fcm.service";
import { IonRouterOutlet } from "@ionic/angular";
import { CrashlyticService } from "./shared/services/crashlytics.service";
import { ActivatedRoute } from "@angular/router";

// import { ScreenOrientation } from '@capacitor/screen-orientation';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: [],
  providers: [IonRouterOutlet]
})
export class AppComponent {
  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    private router: Router,
    private platform: Platform,
    private fcmService: FcmService,
    private routerOutlet: IonRouterOutlet,
    private crashlyticService: CrashlyticService,
    private route: ActivatedRoute
    
  ) {
    this.initializeApp();
    this.setStatusBarStyle();
    this.configureKeyboard();
    this.initializePushNotifications();
    this.disableSwipeToGoBackGestures();
    this.lockScreenPortraitMode();
    this.enableCrashlytics();
    this.checkAppBackgroundStatus();
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
      
    } catch (err) {
      console.log("This is normal in a browser", err);
    }
  }

  checkAppBackgroundStatus(): void {
    if (this.platform.is('capacitor')) {
      this.platform.resume.subscribe(() => {
        console.log('----- RESUME ----');
        setTimeout(() => {
          const queryParams = this.route.snapshot.queryParamMap;
          // Call a page refresh when app enters foreground
          if (!this.platform.is('android') && (queryParams.get('tab') !== '3') && 
          !queryParams.get('partNumber') && !queryParams.get('barcode') && !this.router.url?.includes('tab=3')) {
            window.location.reload();
          }
        }, 100);
      });
  
      this.platform.pause.subscribe(() => {
        console.log('----- PAUSE ----');
      }); 
    }
  }

  lockScreenPortraitMode(): void {
    if (this.platform.is('capacitor')) {
      // ScreenOrientation.lock({orientation: 'portrait'});
    }
  }

  async enableCrashlytics(): Promise<void> {
    if (this.platform.is('capacitor')) {
      await this.crashlyticService.init();
    }
  }

  // Also done in app.module
  disableSwipeToGoBackGestures(): void {
    this.routerOutlet.swipeGesture = false;
  }

  initializePushNotifications(): void {
    this.fcmService.initPush();
  }

  setStatusBarStyle() {
    if (this.platform.is('ios')) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }

  configureKeyboard(): void {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.setAccessoryBarVisible({isVisible: true});
      Keyboard.setResizeMode({mode: KeyboardResize.Ionic});
    }
  }
}
