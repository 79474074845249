<ion-grid>
    <ion-row class="header-row" id="hide-on-camera">
        <ion-col class="header-text">
          <span class="modal-header-text">Defect Report - {{defect.name}}</span>
        </ion-col>
        <ion-col size="auto">
          <ion-icon
            class="cross"
            (click)="closeModal()"
            name="close-outline"
          ></ion-icon>
        </ion-col>
    </ion-row>

    <ion-row class="table-header">
        <ion-col>
            <span>Type</span>
        </ion-col>
        <ion-col>
            <span>Barcode</span>
        </ion-col>
    </ion-row>

    <ion-row *ngFor="let reject of rejects" [ngClass]="{'rework-text': reject.is_rework, 'reject-text': !reject.is_rework}">
        <ion-col>
            <span>{{reject.is_rework ? 'Rework' : 'Reject'}}</span>
        </ion-col>
        <ion-col>
            {{reject.barcode?.length ? reject.barcode : 'N/A'}}
        </ion-col>
    </ion-row>


</ion-grid>