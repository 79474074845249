//import { IUpdate } from "../../../../app/shared/models/update.model";

import { IIssueAttachment } from "../../../../app/shared/models/attachment.model";
import { LiaisonIssueUpdate } from "../../../../app/shared/models/liaison-issue-updates.model";
import { LiaisonSort, Reject } from "../../../../app/shared/models/liaison-sort.model";
import { User } from "../../../../app/shared/models/user.model";
import { IAccepts } from "./sorts.model";
import { LiaisonIssue } from "../../../../app/shared/models/liaison-issue.model";

export interface IDefect {
    //They may want to programaticaly create these down the line. Makes sense to have them as their own model.
    id?: number,
    name?: string,
    accountId:number;
    sortIssueId:number,
    description:string,
    amount:number,
    images?: any[];
    reject?: number;
    rework?: number;
}

export interface ISort {
    //QAS refers to sorting items as a sort, a daily report may have a number of them.
    sortId:number,
    reportId:number,
    binNumber:number,
    partNumber:number,
    total:number,
    passed:number,
    failed:number,
    rework:number,
    defects:TestDefect[]
}

export interface IIssue{
    id?: number,
    issueId:number,
    reportId: number,
    description: string,
    date_created: string,
    concern: boolean,
    //They will only need a concern written if concern is true.
    concernWritten: string,
    active: boolean,
    issue_updates: IUpdate[],
    closed_report_id?: number,
    account_id?: number,
    creator_id?: number,
    initial_report_id?: number,
    parts_found?: number,
    sent_on_creation?: boolean,
    issue_images?: IIssueAttachment[],
    issue_serial_numbers?: any[]
}

export interface INewIssue extends IIssue {
    actionTaken?: string;
    nonConformedParts?: number,
    areaFound?: string,
    grouped_updates?: {date: string, updates: any[]}[]
}

export interface IUpdate {
    id: number;
    issue_id: number;
    content: string;
    created_at: string;
    created_by: number;
    time_created: string;
    is_closing_update: boolean;
    is_action_taken: boolean;
    daily_report_id: number;
    clean_point_required?: boolean;
    clean_point_date?: string;
    parts_found?: number;
    area_found?: string;
    issue_serial_numbers?: any[]
}

export interface IContact {
    contactId:number,
    contactName:string,
    contactPhone:string,
}

export interface IEmployeeShift {
    employeeId: number,
    accountId:number,
    hoursWorked:number,
    dateWorked:string,
}

export interface IDailyReport {
    report_id: number,
    account_id:number,
    date_created: string,
    daily_activity:string,
    production_count:number,
    scrap_count:number
    sorts: Sort[],
    issues: LiaisonIssue[]
}

export class TestDefect implements IDefect{
    accountId: number = null;
    sortIssueId:number = null;
    description:string = "";
    amount:number = 0;
}

export class Sort implements ISort{
    sortId:number = null;
    reportId:number = null;
    binNumber:number = null;
    partNumber:number = null;
    total:number = 0;
    passed:number = 0;
    failed:number = 0;
    rework:number = 0;
    defects:IDefect[] = [];
    part_numbers?: PartNumber[] = [];
    rejects?: Reject[] = [];
    amount_accepted?: number = 0;
    accepts?: IAccepts[];
}

export class PartNumber {
    account_id: number = null;
    barcode: string = '';
    id: number = null;
    part_number: string = null;
}

export class DailyReport implements IDailyReport{
    id?: number;
    report_id: number = null;
    account_id: number = null;
    date_created: string = "";
    daily_activity: string = "";
    production_count: number = null;
    scrap_count:number = 0;
    sorts: Sort[] = [];
    issues: LiaisonIssue[] = [];
    shifts: IEmployeeShift[] = [];
    users?: User = null;
    issues_issues_closed_report_idTodaily_reports?: LiaisonIssue[] = [];
    issues_issues_initial_report_idTodaily_reports?: LiaisonIssue[] = [];
    customer_report_status: CustomerReadStatus[];
    issue_updates?: LiaisonIssueUpdate[] = [];
    expanded?: boolean = false;
}

export class CustomerReadStatus {
    contact_id: number;
    id: number;
    read: boolean; 
    report_id: number;
}