import { Component, OnInit } from "@angular/core";
import { AdminApiService } from "../../../../../../../app/features/admin/services/admin-api-service";
import { ViewWillEnter } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { ILiaisonAccount, LiaisonAccount } from "../../../../../../../app/shared/models/liaison-account.model";
import { User } from "../../../../../../../app/shared/models/user.model";
import { ILiaisonAccountHistory } from "../../../../../../shared/models/liaison-account-history.model";
import { IAccountData } from "../../../../../../../app/shared/models/account.model";
import { SharedService } from "../../../../../../../app/shared/services/shared.service";
import { IAccountHistory } from "../../../../../../../app/shared/models/account-history.model";

@Component({
    selector: 'assign-liaison-new-account-component',
    templateUrl: 'assign-liaison-new-account.component.html',
    styleUrls:['./assign-liaison-new-account.component.scss'],
    providers:[ AdminApiService ]
})
export class AssignLiaisonNewAccountComponent implements OnInit {

    user: User = null;

    selectUser: boolean = false;

    liaisonHistory: ILiaisonAccountHistory = null;

    initialAccountId: number = null;
    excludeUserIdList: number[] = [];

    accounts: IAccountData[] = [];
    selectedAccount: IAccountData = null;
    liaisons: User[] = [];

    accountData: IAccountHistory = null;

    minStartDate: string = null;
    startDate: Date = null;

    trigger: number = 0;

    saveInProgress: boolean = false;

    loading: boolean = true;

    constructor(
        private modalController: ModalController,
        private adminApiService: AdminApiService,
        private sharedService: SharedService
    ) {

    }

    async ngOnInit(): Promise<void> {
        const promises: any[] = [];

        this.liaisonHistory = {
            id: null,
            liaison_account: null,
            liaison: this.user?.id,
            rate: 0,
            hours: 0,
            start_date: this.sharedService.getDefaultDate(new Date().toISOString()) as any,
            end_date: null
        };

        if (this.accounts.length <= 0) {
            promises.push(this.getAccounts());
        }

        if (!this.user || this.selectUser) {
            promises.push(this.getLiaisons());
        }

        await Promise.all(promises);

        this.setInitialAccount();
        this.filterUsers();

        this.loading = false;
    }

    filterUsers(): void {
        this.liaisons = this.liaisons.filter(l => !this.excludeUserIdList.includes(l.id));
    }

    setInitialAccount(): void {
        if (this.initialAccountId && this.accounts) {
            const account: IAccountData = this.accounts.find(a => a.id == this.initialAccountId);

            if (account) {
                this.selectedAccount = account;
            }
        }
    }

    async getCurrentAccountSettings(): Promise<void> {
        if (this.selectedAccount) {
            this.accountData = await this.adminApiService.getAccountHistoryStartDateAccountId(this.selectedAccount?.id);

            if (this.accountData && this.accountData.start_date) {
                const date = new Date(new Date(this.accountData.start_date).setHours(24, 0, 0, 0));
                this.minStartDate = `${date.getFullYear()}-${((date.getMonth() + 1) < 10) ? '0' : ''}${date.getMonth() + 1}-${(date.getDate() < 10) ? '0' : ''}${date.getDate()}`;
                this.startDate = date;
            }
        }
    }

    async getAccounts(): Promise<void> {
        this.accounts = (await this.adminApiService.getAccountsData()).filter(a => this.sharedService.isActiveAccount(a)).sort((a, b) => {
            if (b.name < a.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
        });
    }

    userChanged(): void {
        if (!this.initialAccountId) {
            this.trigger++;
            this.selectedAccount = null;
        } 
    }

    async accountUpdate(): Promise<void> {
        if (this.selectedAccount) {
            await this.getCurrentAccountSettings();

            if (this.startDate && (this.startDate > new Date())) {
                (this.liaisonHistory.start_date as any) = this.sharedService.getDefaultDate(this.startDate.toISOString());
            }
        }
    }

    async getLiaisons(): Promise<void> {
        const allLiaison = (await this.adminApiService.getUsers('liaison', !this.getAuthorizationStatus()));
        this.liaisons = this.sharedService.sortFirstNameLastName<User>(allLiaison?.filter(l => l.active));
    }


    async save(): Promise<void> {
        if ((!this.selectedAccount || !this.user) || this.saveInProgress) {
            return;
        }
        
        this.saveInProgress = true;

        const newAssigned: ILiaisonAccount = {
            account_id: this.selectedAccount.id,
            base_hours: null,
            pay_rate: null,
            user_id: this.user.id
        };

        const accountLiaison = await this.adminApiService.addLiaisonAccount(newAssigned);
        if (accountLiaison) {
            this.liaisonHistory.liaison_account = accountLiaison.id;

            if (!this.liaisonHistory.liaison) {
                this.liaisonHistory.liaison = this.user.id;
            }

            await this.adminApiService.postHistoryByLiaisonAccount(this.liaisonHistory);

            this.sharedService.presentToast('primary', 'Liaison was assigned successfully.', 'Success!', 'med');
        } else {
            this.sharedService.presentToast('danger', 'Liaison account failed to create.', 'Failed!', 'med');
        }

        this.saveInProgress = false;

        this.close(true);
    }

    compareBaseHours(a: ILiaisonAccountHistory, b: ILiaisonAccountHistory): boolean {
        return (a.end_date === b.end_date) && (a.hours === b.hours) && (a.start_date === b.start_date) && (a.rate == b.rate) && (a.liaison === b.liaison);
    }


    close(update: boolean = false): void {
        this.modalController.dismiss(update);
    }

    getAuthorizationStatus(): boolean {
        return (JSON.parse(this.sharedService.localStorageGet('user'))?.role === 'admin') ?? false;
    }

}